<template>
  <v-container>
    <v-row>
      <v-col>
        <div
          class="page--password-recovery d-flex justify-center align-center"
        >
          <div class="form-container">
            <v-card class="pa-10" color="rgba(255, 255, 255, .8)">
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col class="text-center">
                    <img
                      src="/img/brasao_64.png"
                      alt="Brasão do state de Pernambuco"
                    />
                    <br />
                    <h1 class="subtitle-1 blue--text text--darken-4">
                      Recuperar Senha
                    </h1>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.username"
                      solo
                      hide-details="auto"
                      placeholder="Nome de usuário"
                      :rules="regrasDeNomeDeUsuario"
                      @keyup.enter="requestPasswordRecovery"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn block color="success" :loading="loading" @click="requestPasswordRecovery">
                      Recuperar
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0 mt-3 text-center">
                    <small>
                      <router-link :to="{ path: '/Login' }">
                        Entrar no sistema
                      </router-link>
                    </small>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import configurations from "commons/configurations";
import pageLoadingMixin from "mixins/page-loading.mixin";
import notificationMixin from "mixins/notification.mixin";
import Validations from "services/validations/validations.js";

export default {
  name: "PasswordRecovery",
  components: {},
  mixins: [pageLoadingMixin, notificationMixin],
  data: () => ({
    valid: false,
    loading: false,
    user: {
      username: undefined,
    },
  }),
  computed: {
    regrasDeNomeDeUsuario() {
      return [
        (value) => Validations.required(value, "Nome é obrigatório"),
        (value) => Validations.min(value, 2, "Nome deve ter pelo menos 2 caracteres"),
      ];
    }
  },
  beforeMount() {
    this.hidePageLoading();
  },
  methods: {
    requestPasswordRecovery() {
      this.$refs.form.validate();
      
      if(!this.valid) {
        return;
      }
      
      this.loading = true;

      this.$api()
        .post(configurations.api.auth.passwordRecoveryUri, {
          nomeDeUsuario: this.user.username,
        })
        .then(({ data }) => {
          if (data.sucesso) {
            this.$notifySuccess(data.mensagem);
            this.$router.push({ path: "/login" });
          } else {
            this.loading = false;
            this.$notifyError(data.mensagem);
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          if (response && response.data) {
            this.$notifyError(response.data.mensagem);
          } else {
            this.$notifyError(
              "Não conseguimos nos comunicar com o servidor da aplicação."
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page--password-recovery {
  min-height: calc(100vh - 24px) !important;
}
</style>
