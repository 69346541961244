var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "page--password-recovery d-flex justify-center align-center"
  }, [_c('div', {
    staticClass: "form-container"
  }, [_c('v-card', {
    staticClass: "pa-10",
    attrs: {
      "color": "rgba(255, 255, 255, .8)"
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": "/img/brasao_64.png",
      "alt": "Brasão do state de Pernambuco"
    }
  }), _c('br'), _c('h1', {
    staticClass: "subtitle-1 blue--text text--darken-4"
  }, [_vm._v(" Recuperar Senha ")])])], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "solo": "",
      "hide-details": "auto",
      "placeholder": "Nome de usuário",
      "rules": _vm.regrasDeNomeDeUsuario
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.requestPasswordRecovery.apply(null, arguments);
      }
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "success",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.requestPasswordRecovery
    }
  }, [_vm._v(" Recuperar ")])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0 mt-3 text-center"
  }, [_c('small', [_c('router-link', {
    attrs: {
      "to": {
        path: '/Login'
      }
    }
  }, [_vm._v(" Entrar no sistema ")])], 1)])], 1)], 1)], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }